import {
  MediaStore,
  MediaUploadOptions,
  Media,
  MediaList,
  MediaListOptions,
} from '@tinacms/core'

import axios from 'axios';

import { getGroups } from '../base.js'

export class S3MediaStore implements MediaStore {
  accept = '*'
  async persist(files: MediaUploadOptions[]): Promise<Media[]> {
    const uploaded: Media[] = []
      console.log(`upload: ${JSON.stringify(files)}`)

      const formData = new FormData()
      formData.append("operations", JSON.stringify({ "query": "mutation UploadFile($file:Upload!, $uuid:String) { UploadFile(file: $file, uuid:$uuid)}", "variables": { "uuid": files[0].directory }}));
      // formData.append("operations", JSON.stringify({"query":"mutation UploadFile($file:Upload!) {\n  UploadFile(file: $file)\n}"}));
      formData.append("map", JSON.stringify({"0": ["variables.file"]}));
      formData.append("0", files[0].file);

      console.log(`formdata:${JSON.stringify(formData)}`)

      axios({
          url: process.env.REACT_APP_API,
          method: "post",
          data: formData
      })
      .then(response => { console.log(`response:${JSON.stringify(response)}`) })
      .catch(error => console.log);

    return uploaded
  }

  async previewSrc(filename: string) {
    //console.log('previewSrc')
    return filename
  }

  async list(options?: MediaListOptions): Promise<MediaList> {
    const directory = options?.directory ?? ''
    let groups = await getGroups();
    const items: Media[] = [];

    let filesOut = await axios({
      url: process.env.REACT_APP_API,//'https://d2cx.dev/graphql',
      method: 'post',
      data: {
        query: `
        query GetFiles($company: CompanyInputType, $dir: String) {
          GetFiles(company: $company, dir:$dir){
            filename
            uuid
            id
            mime
          }
        }`,
        variables:{
          dir:directory
        }
      }
    }).then((result) => {
      return result.data.data.GetFiles
    })
    .catch((err) => {
      console.log('array-err:', err)
      }
    )

    if(directory === ''){
      Object(groups).forEach((element: string) => {
        let folderItem: Media = {
          id: element,
          filename:element,
          directory: '/',
          type: 'dir',
        }
        items.push(folderItem)
      });
    }

    filesOut.forEach((element: Media) => {
      let mediaItem: Media = {
        id: element.id,
        filename: `${element.filename}`,
        directory: 'https://imp-space.ams3.digitaloceanspaces.com/',
        type: 'file',
        previewSrc: `https://imp-space.ams3.digitaloceanspaces.com/${element.id}`
      }

      items.push(mediaItem)
    });

    return {
      items,
      offset: 0,
      limit: 10,
      totalCount: items.length,
    }
  }

  async delete(media: Media) {
    axios({
      url: process.env.REACT_APP_API,
      method: 'post',
      data: {
        query: `mutation DeleteFile($fileId:String){DeleteFile(fileId:$fileId)}`,
        variables:{
          fileId:media.id
        }
      }
    })
    .then((result) => console.log('delete:', result.data.data.DeleteFile))
    .catch((err) => console.log('err:', err))
  }
}
