import { initializeApp } from "firebase/app";
import { GoogleAuthProvider, getAuth, signInWithPopup } from "firebase/auth";

import axios from "axios";

const firebaseConfig = {
  apiKey: process.env.REACT_APP_FIREBASE_KEY,
  authDomain: process.env.REACT_APP_FIREBASE_DOMAIN,
  projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
  storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
  messagingSenderId: process.env.REACT_APP_FIREBASE_SENDER_ID,
};

const app = initializeApp(firebaseConfig);
export const auth = getAuth(app);

const googleProvider = new GoogleAuthProvider();
export const signInWithGoogle = async () => {
  try {
    const res = await signInWithPopup(auth, googleProvider);
    const user = res;
    console.log({ user });
    return user;
  } catch (err) {
    console.error(err);
    alert(err.message);
  }
};

export const isAdmin = async (companyId) => {
  return auth.currentUser
    .getIdTokenResult()
    .then((idTokenResult) => {
      if (!!idTokenResult.claims.admin || !!idTokenResult.claims[companyId]) {
        return true;
      } else {
        return false;
      }
    })
    .catch((error) => {
      console.log(error);
      return false;
    });
};

export const isPortalUser = async () => {
  return auth.currentUser
    .getIdTokenResult()
    .then((idTokenResult) => {
      console.log("idTokenResult.claims", idTokenResult.claims);
      if (!!idTokenResult.claims.sso || !idTokenResult.claims.newUser) {
        console.log("claims:", idTokenResult.claims);
        return true;
      }
      if (!idTokenResult.claims.newUser) {
        return true;
      } else {
        console.log("!claims:", idTokenResult.claims);
        return false;
      }
    })
    .catch((error) => {
      console.log(error);
      return false;
    });
};

export const isInvestorUser = async () => {
  return auth.currentUser
    .getIdTokenResult()
    .then((idTokenResult) => {
      console.log("idTokenResult.claims", idTokenResult.claims);
      if (idTokenResult.claims.investor) {
        console.log("claims:", idTokenResult.claims);
        return true;
      }
    })
    .catch((error) => {
      console.log(error);
      return false;
    });
};

export const isMentorUser = async () => {
  return auth.currentUser
    .getIdTokenResult()
    .then((idTokenResult) => {
      console.log("idTokenResult.claims", idTokenResult.claims);
      if (idTokenResult.claims.mentor) {
        console.log("claims:", idTokenResult.claims);
        return true;
      } else {
        return false;
      }
    })
    .catch((error) => {
      console.log(error);
      return false;
    });
};

export const getProgram = async () => {
  return auth.currentUser
    .getIdTokenResult()
    .then((idTokenResult) => {
      console.log("idTokenResult.claims.program", idTokenResult.claims);
      let programs = [];
      if (idTokenResult.claims.ftc22) programs.push("ftc22");
      if (idTokenResult.claims.ica22) programs.push("ica22");
      if (idTokenResult.claims.sus22) programs.push("sus22");
      if (idTokenResult.claims.fpt22) programs.push("fpt22");
      if (idTokenResult.claims.sse22) programs.push("sse22");
      if (idTokenResult.claims.ssi22) programs.push("ssi22");
      if (idTokenResult.claims.mob22) programs.push("mob22");
      if (idTokenResult.claims.wsu22) programs.push("wsu22");
      if (idTokenResult.claims.rnz23) programs.push("rnz23");
      if (idTokenResult.claims.ifd23) programs.push("ifd23");
      if (idTokenResult.claims.mom23) programs.push("mom23");

      if (programs.length > 0) {
        return programs;
      } else return false;
    })
    .catch((error) => {
      console.log(error);
      return false;
    });
};

const getCompanyNames = (input) => {
  return axios({
    url: process.env.REACT_APP_API, //'https://d2cx.dev/graphql',
    method: "post",
    data: {
      query: `
      query GetCompanyNames($companyUuidList:[String]){
        GetCompanyNames(companyUuidList:$companyUuidList){
          companyName
          uuid
        }
      }`,
      variables: {
        companyUuidList: [...input],
      },
    },
  })
    .then((result) => {
      return result.data.data.GetCompanyNames;
    })
    .catch((err) => {
      console.log("array-err:", err);
    });
};

export const getGroups = async () => {
  return auth.currentUser
    .getIdTokenResult()
    .then(async (idTokenResult) => {
      let _claims = idTokenResult.claims;
      const defaultKeys = [
        "iss",
        "aud",
        "auth_time",
        "user_id",
        "sub",
        "iat",
        "exp",
        "email",
        "email_verified",
        "firebase",
      ];
      defaultKeys.forEach((item) => delete _claims[item]);
      _claims = Object.keys(_claims).filter((item) => _claims[item]);
      let nameArr = await getCompanyNames(_claims);
      _claims.forEach((item, i) => {
        let foundItem = nameArr.find((x) => x.uuid === item);
        if (foundItem !== undefined) _claims[i] = foundItem.companyName;
      });

      return _claims;
    })
    .catch((error) => {
      console.log(error);
      return false;
    });
};

export const getToken = async () => {
  let token;
  if (auth.currentUser) {
    token = await auth.currentUser.getIdToken(true);
    //console.log('token:', token)
    return token;
  } else {
    return "";
  }
};

export const getEmail = async () => {
  let userEmail;
  //console.log('user:', auth().currentUser)
  if (auth.currentUser) {
    userEmail = await auth.currentUser.email;
    //console.log('userEmail:', userEmail)
    return userEmail;
  } else {
    return "";
  }
};

export const getHeaders = async () => {
  let token;
  if (auth.currentUser) {
    token = await auth.currentUser.getIdToken(true);
    //console.log('token:', token)
    return {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    };
  } else {
    return {};
  }
};
