import React, { useEffect, useState } from "react";
import LoadingBar from 'react-top-loading-bar'
import { auth } from "../../base.js";

export const AuthContext = React.createContext();

export const AuthProvider = ({ children }) => {
  const [currentUser, setCurrentUser] = useState(null);
  const [pending, setPending] = useState(true);
  const [progress, setProgress] = useState(0);

  useEffect(() => {
    setProgress(10)
    auth.onAuthStateChanged((user) => {
      setProgress(50)
      setCurrentUser(user)
      setProgress(100)
      setPending(false)
    });
  }, []);

  return (
    <>
      {
        pending ? <LoadingBar color="#46008c" progress={progress}
          onLoaderFinished={() => setProgress(100)} />
          :
          <>
            <LoadingBar color="#46008c" progress={100}
              onLoaderFinished={() => setProgress(100)} />
            <AuthContext.Provider value={{ currentUser }}>
              {children}
            </AuthContext.Provider>
          </>
      }
    </>
  );
};
