import React from "react";
import { Spinner } from "reactstrap";

const LoadingOverlay = () => {
  return (
    <div
      style={{
        width: "100%",
        height: "100%",
        position: "fixed",
        top: 0,
        left: 0,
        zIndex: 1051,
        overflow: "hidden",
      }}
      className="d-flex justify-content-center align-items-center"
    >
      <Spinner />
    </div>
  );
};

export default LoadingOverlay;
